import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入客户名称',
    },
    {
      type: 'input',
      prop: 'contactsName',
      value: '',
      placeholder: '请输入联系人',
    },
    {
      type: 'multiSelect',
      prop: 'source',
      value: [],
      options: 'event_source',
      width: 180,
      placeholder: '请选择来源',
    },
    {
      type: 'multiSelect',
      prop: 'status',
      value: [1, 2],
      options: 'leads_status',
      width: 180,
      placeholder: '请选择状态',
    },
    {
      type: 'signSelect',
      prop: 'isTransformed',
      value: 0,
      options: 'leads_tranform',
      placeholder: '请选择转化',
    },
    {
      type: 'input',
      prop: 'userName',
      value: '',
      placeholder: '请输入负责人',
    },
    {
      type: 'time',
      prop: ['startTime', 'endTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
    {
      type: 'time',
      prop: ['startDate', 'endDate'],
      value: [],
      placeholder: ['更新开始时间', '更新结束时间'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '客户名称',
      prop: 'customerName',
      width: 238,
    },
    {
      label: '联系人',
      prop: 'contactsName',
      width: 188,
    },
    {
      label: '联系电话',
      prop: 'contactsTelephone',
      width: 188,
    },
    {
      label: '来源',
      prop: 'source',
      propDesc: 'sourceName', // 需要转换的列值的属性名
      width: 188,
    },
    {
      label: '状态',
      prop: 'status',
      propDesc: 'statusName', // 需要转换的列值的属性名
      width: 188,
    },
    {
      label: '是否转化',
      prop: 'isTransformed',
      propDesc: 'isTransformedName', // 需要转换的列值的属性名
      width: 125,
    },
    {
      label: '负责人',
      prop: 'userNameList',
      width: 125,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 188,
    },
    {
      label: '更新时间',
      prop: 'lastEditTime',
    },
  ];
  return { filterOptions, columnList, tableRef };
};
